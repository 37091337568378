<template>
  <div>
    <PageHeaderLayout>
      <div class='main-page-content'>
        <el-row class='table-header'>
          <el-form v-model='searchCondition' :inline='true'>
            <el-form-item label='状态：'>
              <el-select v-model='searchCondition.status' @change='search' clearable>
                <el-option :label='label' :value='value' v-for='(label,value) in options.status'
                           :key='value'></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label='数据来源：'>
              <el-select v-model='searchCondition.source_type' @change='search' clearable>
                <el-option :label='label' :value='value' v-for='(label,value) in options.source_types'
                           :key='value'></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type='primary' @click='search'>搜索</el-button>
            </el-form-item>
          </el-form>
        </el-row>
        <br />
        <!--   table列表     -->
        <div class='default-table'>
          <ApeTable ref='apeTable'
                    :data='dataList'
                    :columns='columns'
                    :loading='loadingStatus'
                    :pagingData='pagingData'
                    @switchPaging='switchPaging'
                    @changeTableSort='changeTableSort'
                    highlight-current-row
                    border>
            <el-table-column
              slot='second-column'
              width='64'
              label='序号' align='center' fixed='left'>
              <template slot-scope='scope'>
                <span>{{ offset + scope.$index + 1 }}</span>
              </template>
            </el-table-column>
            <!--            <el-table-column slot="second-column" prop="nickname" label="红人昵称" min-width="120" align="center"-->
            <!--                             show-overflow-tooltip sortable="custom">-->
            <!--              <template slot-scope="scope">-->
            <!--                <artist-nickname :info="scope.row"/>-->
            <!--              </template>-->
            <!--            </el-table-column>-->

            <el-table-column
              width='100'
              label='操作' align='center' fixed='right'>
              <template slot-scope='{row}'>
                <export-link v-if='row.file_path' :ref='`refExportLink_${row.id}`' :title='`下载当前文件`'
                             :lint-title='`下载`' :class-name='`export-btn`'
                             :can-export="userPermissions.indexOf('download_file_export')>-1"
                             @export='exportData(row)' />
              </template>
            </el-table-column>

          </ApeTable>
        </div>

      </div>
    </PageHeaderLayout>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import PageHeaderLayout from '@/layouts/PageHeaderLayout'
import ApeTable from '@/components/ApeTable'
import ExportLink from '@/components/export/ExportLink'

export default {
  name: 'DownloadFileList',
  components: {
    PageHeaderLayout,
    ApeTable,
    ExportLink
  },
  computed: {
    ...mapGetters(['userPermissions', 'buttonType'])
  },
  data() {
    return {
      loadingStatus: false,
      // 表格列表数据
      dataList: [],
      // 分页信息
      pagingData: {
        is_show: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        page_size: 15
        // offset: 0,
      },
      // 分页的offset,序号列使用
      offset: 0,
      columns: [
        {
          title: '数据ID',
          value: 'id',
          width: 80,
          header_align: 'center',
          align: 'center',
          sortable: 'custom'
        },
        {
          title: '标题',
          value: 'title',
          width: 200,
          header_align: 'center',
          align: 'center',
          sortable: 'custom'
        },
        {
          title: '数据来源',
          value: 'source_type_alias',
          width: 100,
          header_align: 'center',
          align: 'center',
          sortable: 'custom'
        },
        {
          title: '数据量',
          value: 'rows_count',
          width: 80,
          header_align: 'center',
          align: 'center',
          sortable: 'custom'
        },
        {
          title: '状态',
          value: 'status_alias',
          width: 80,
          header_align: 'center',
          align: 'center',
          sortable: 'custom'
        },
        {
          title: '耗时',
          value: 'used_sec',
          width: 80,
          header_align: 'center',
          align: 'center',
          sortable: 'custom'
        },
        {
          title: '下载次数',
          value: 'download_count',
          width: 80,
          header_align: 'center',
          align: 'center',
          sortable: 'custom'
        },
        {
          title: '备注',
          value: 'remark',
          width: 120,
          header_align: 'center',
          align: 'left'
        },
        {
          title: '创建时间',
          value: 'created_at',
          width: 120,
          header_align: 'center',
          align: 'center',
          sortable: 'custom'
        }, {
          title: '修改',
          value: 'updated_at',
          width: 120,
          header_align: 'center',
          align: 'center',
          sortable: 'custom'
        }
      ],
      searchCondition: { status: null, source_type: null },
      options: { status: {}, source_types: {} },
      orderSort: { id: 'desc' }//默认排序规则
    }
  },
  methods: {
    search() {
      this.$refs['apeTable'].handleCurrentChange(1)
    },
    // 切换页码操作
    async switchPaging() {
      await this.initList()
    },
    changeTableSort({ prop, order }) {
      if (prop === 'status_alias') {
        prop = 'status'
      }
      let orderSort = {}
      if (order) {
        orderSort[prop] = order === 'descending' ? 'desc' : 'asc'
      }
      this.orderSort = orderSort
      this.search()
    },
    // 处理搜索条件
    handleSearchCondition() {
      let condition = {}

      if (this.searchCondition.status) {
        condition.status = this.searchCondition.status
      }
      if (this.searchCondition.source_type) {
        condition.source_type = this.searchCondition.source_type
      }
      return condition
    },
    // 初始化列表
    async initList(type) {
      this.loadingStatus = true
      let pagingInfo = this.$refs['apeTable'].getPagingInfo(type)
      let searchCondition = this.handleSearchCondition()
      // Object.assign(searchCondition, pagingInfo)
      Object.assign(searchCondition, pagingInfo, { order: this.orderSort })
      this.dataList = []
      let { list, pages } = await this.$api.getDownLoadFileList(searchCondition)
      this.$nextTick(() => {
        this.dataList = list
      })
      this.pagingData.total = pages.total
      this.offset = pages.offset
      this.loadingStatus = false
    },
    async getOptions() {
      let options = await this.$api.getDownLoadFileOptions()
      this.options = options
    },
    async exportData(row) {
      try {
        let name = `${row.title}`
        let response = await this.$api.exportDownLoadFile(row.id)

        this.$refs[`refExportLink_${row.id}`].exportSaveXlsx(response, name)
        row.download_count += 1
      } catch (e) {
        this.$message.warning('导出异常，请联系管理员')
        // alert(JSON.stringify(e))
      }
    }
  },
  async mounted() {
    await this.getOptions()
    await this.initList()
  }
}
</script>

<style scoped>

</style>
